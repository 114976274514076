import i18n from "@/i18n";

const routes = {
  path: "/membership",
  name: "membership",
  component: () =>
    import(
      /* webpackChunkName: "membership" */ "@/views/membership/MembershipMain"
    ),
  beforeEnter(to, from, next) {
    if (sessionStorage.getItem("token")) {
      next();
    } else {
      next({ name: "Login" });
    }
  },
  children: [
    {
      path: "mysummary",
      alias: "",
      name: "MySummary",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MySummary"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.membership.my_summary"),
        m_subtitle: i18n.t("page.membership.my_summary"),
        menu_num: 1,
        sub_menu_num: 11,
        step: [
          i18n.t("page.membership.membership"),
          i18n.t("page.membership.my_summary"),
        ],
        location: "/membership/mysummary",
      },
    },
    {
      path: "myrecords",
      name: "MyRecords",
      component: () =>
        import(
          /* webpackChunkName: "membership" */ "@/views/membership/MyRecords"
        ),
      children: [
        {
          path: "myrounds",
          alias: "",
          name: "MyRounds",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyRounds"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_rounds"), */
            ],
            location: "/membership/myrecords/myrounds",
          },
        },
        {
          path: "myroundsview/:id",
          name: "MyRoundsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyRoundsView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_rounds"), */
            ],
            location: "/membership/myrecords/myrounds",
          },
        },
        {
          path: "mytournaments",
          name: "MyTournaments",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournaments"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_tournaments"), */
            ],
            location: "/membership/myrecords/mytournaments",
          },
        },
        {
          path: "mytournamentsview/:id",
          name: "MyTournamentsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_tournaments"), */
            ],
            location: "/membership/myrecords/mytournaments",
          },
        },
        {
          path: "mytournamentsattend/:id",
          name: "MyTournamentsAttend",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsAttend"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_tournaments_attend"), */
            ],
            location: "/membership/myrecords/mytournaments",
          },
        },
        {
          path: "myscorecard/:id",
          name: "MyScorecard",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyScorecard"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_tournaments_view"), */
            ],
            location: "/membership/myrecords/mytournaments",
          },
        },
        {
          path: "mytournamentsranking/:id",
          name: "MyTournamentsRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsRanking"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_tournaments"), */
            ],
            location: "/membership/myrecords/mytournaments",
          },
        },
        {
          path: "myevent",
          name: "MyEvent",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyEvent"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_events"), */
            ],
            location: "/membership/myrecords/myevent",
          },
        },
        {
          path: "myminigameranking/:eventno",
          name: "MyMiniGameRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyMiniGameRanking"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_mini_game_rank"), */
            ],
            location: "/membership/myrecords/myevent",
          },
        },
        {
          path: "mywinaminigame/:eventno",
          name: "MyWinAMiniGame",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyWinAMiniGame"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_events"), */
            ],
            location: "/membership/myrecords/myevent",
          },
        },
        {
          path: "myxpoint",
          name: "MyXpoint",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyXpoint"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
            location: "/membership/myrecords/myxpoint",
          },
        },
        {
          path: "mypayment",
          name: "MyPayment",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyPayment"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
            location: "/membership/myrecords/mypayment",
          },
        },
        {
          path: "myswing",
          name: "MySwing",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwing"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_swings"), */
            ],
            location: "/membership/myrecords/myswing",
          },
        },
        {
          path: "myswingview/:id",
          name: "MySwingView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwingView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
              /* i18n.t("page.membership.my_swings"), */
            ],
            location: "/membership/myrecords/myswing",
          },
        },
      ],
    },
    {
      path: "myaccount",
      name: "MyAccount",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MyAccount"
        ),
      children: [
        {
          path: "myprofile",
          alias: "",
          name: "MyProfile",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyProfile"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
              /* i18n.t("page.membership.my_profile"), */
            ],
            location: "/membership/myaccount/myprofile",
          },
        },
        {
          path: "favoritelocation",
          name: "FavoriteLocation",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocation"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
              /* i18n.t("page.membership.favorite_location"), */
            ],
            location: "/membership/myaccount/favoritelocation",
          },
        },
        {
          path: "favoritelocationinfo/:shopno",
          name: "FavoriteLocationInfo",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocationInfo"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
              /* i18n.t("page.membership.favorite_location_info"), */
            ],
            location: "/membership/myaccount/favoritelocation",
          },
        },
        {
          path: "confirmpassword",
          name: "ConfirmPassword",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/ConfirmPassword"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.confirm_password"),
            m_subtitle: i18n.t("page.membership.confirm_password"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
              /* i18n.t("page.membership.cancel_membership"), */
            ],
            location: "/membership/myaccount/confirmpassword",
          },
        },
        {
          path: "cancelmember",
          name: "CancelMember",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/CancelMember"
            ),
          props: true,
          password_yn: "",
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
              /* i18n.t("page.membership.cancel_membership"), */
            ],
            location: "/membership/myaccount/confirmpassword",
          },
        },
      ],
    },
    // {
    //   path: "mybrandroom",
    //   name: "MyBrandRoom",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "membership" */ "@/views/membership/MyBrandRoom"
    //     ),
    //   children: [
    //     {
    //       path: "fittinglist",
    //       alias: "",
    //       name: "FittingList",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "membership" */ "@/views/membership/FittingList"
    //         ),
    //       prop: true,
    //       meta: {
    //         layout: "DefaultLayout",
    //         wrapId: "sub",
    //         subtitle: i18n.t("page.membership.my_brandroom"),
    //         m_subtitle: i18n.t("page.membership.my_brandroom"),
    //         menu_num: 1,
    //         sub_menu_num: 14,
    //         step: [
    //           i18n.t("page.membership.membership"),
    //           i18n.t("page.membership.my_brandroom"),
    //           i18n.t("page.title.membership.fitting_list"),
    //         ],
    //       },
    //     },
    //     {
    //       path: "fittingswinglist/:fittingno",
    //       alias: "",
    //       name: "FittingSwingList",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "membership" */ "@/views/membership/FittingSwingList"
    //         ),
    //       prop: true,
    //       meta: {
    //         layout: "DefaultLayout",
    //         wrapId: "sub",
    //         subtitle: i18n.t("page.membership.my_brandroom"),
    //         m_subtitle: i18n.t("page.membership.my_brandroom"),
    //         menu_num: 1,
    //         sub_menu_num: 15,
    //         step: [
    //           i18n.t("page.membership.membership"),
    //           i18n.t("page.membership.my_brandroom"),
    //           i18n.t("page.membership.fitting_swing_view"),
    //         ],
    //       },
    //     },
    //     {
    //       path: "fittingrequestwrite/:fittingno/:fitreqno?",
    //       name: "FittingRequestWrite",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "tournament" */ "@/views/membership/FittingRequestWrite"
    //         ),
    //       prop: true,
    //       meta: {
    //         layout: "DefaultLayout",
    //         wrapId: "sub",
    //         subtitle: i18n.t("page.membership.my_brandroom"),
    //         m_subtitle: i18n.t("page.membership.my_brandroom"),
    //         menu_num: 1,
    //         sub_menu_num: 16,
    //         step: [
    //           i18n.t("page.membership.membership"),
    //           i18n.t("page.membership.my_brandroom"),
    //           i18n.t("page.membership.fitting_register"),
    //         ],
    //       },
    //     },
    //     {
    //       path: "fittinganswerview/:fitreqno",
    //       name: "FittingAnswerView",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "tournament" */ "@/views/membership/FittingAnswerView"
    //         ),
    //       prop: true,
    //       meta: {
    //         layout: "DefaultLayout",
    //         wrapId: "sub",
    //         subtitle: i18n.t("page.membership.my_brandroom"),
    //         m_subtitle: i18n.t("page.membership.my_brandroom"),
    //         menu_num: 1,
    //         sub_menu_num: 17,
    //         step: [
    //           i18n.t("page.membership.membership"),
    //           i18n.t("page.membership.my_brandroom"),
    //           i18n.t("page.membership.fitting_view"),
    //         ],
    //       },
    //     },
    //     {
    //       path: "fittingsaleview/:fitansno",
    //       name: "FittingSaleView",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "tournament" */ "@/views/membership/FittingSaleView"
    //         ),
    //       prop: true,
    //       meta: {
    //         layout: "DefaultLayout",
    //         wrapId: "sub",
    //         subtitle: i18n.t("page.membership.my_brandroom"),
    //         m_subtitle: i18n.t("page.membership.my_brandroom"),
    //         menu_num: 1,
    //         sub_menu_num: 18,
    //         step: [
    //           i18n.t("page.membership.membership"),
    //           i18n.t("page.membership.my_brandroom"),
    //           i18n.t("page.membership.fitting_purchases_view"),
    //         ],
    //       },
    //     },
    //   ],
    // },
  ],
};

export default routes;
